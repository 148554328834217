@mixin mobile {
    @media only screen and (max-width: 670px) { // 768px
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: 670px) and (max-width: 1200px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}

@mixin tablet-and-up {
  @media (min-width: 768px) {
    @content;
  }
}

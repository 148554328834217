@use '@angular/material' as mat;
@import 'mixins';
@include mat.core();

$my-primary: mat.m2-define-palette(mat.$m2-blue-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-lime-palette, A200, A100, A400);

$my-theme: mat.m2-define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 ),
 typography: mat.m2-define-typography-config(),
 density: 0,
));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.all-component-themes($my-theme);

@import '~firebaseui/dist/firebaseui.css';

// regular style toast
@import 'ngx-toastr/toastr';

/* Reset CSS */
html,
body,
div,
span,
app-root {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* Box sizing border-box */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Set primary color */
:root {
  --primary-color: #0091ff;
}

/* Body styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  color: #333;
  line-height: 1.6;
}

/* Padding and Margin */
.container {
  max-width: 1900px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Typography */
h1:not(markdown h1),
h2:not(markdown h2),
h3:not(markdown h3),
h4:not(markdown h4),
h5:not(markdown h5),
h6:not(markdown h6)  {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  margin-top: 0.5rem !important;
  margin-bottom: 0 !important;
  border-bottom: 1px solid rgba(0, 145, 255, 0.5);
  color: var(--primary-color);
}

p:not(markdown p) {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Links */
a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Buttons */
.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #007acc; /* slightly darker shade for hover effect */
}

/* Input fields */
input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

/* Forms */
form {
  margin-bottom: 1rem;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-mdc-dialog-panel {
  @include mobile {
    max-width: 98vw !important;
  }

  @include tablet {
    max-width: 1280px !important;
    width: 90%;
  }
}

.mat-mdc-tab-body-wrapper {
  flex-grow: 1;
}

.bar-tooltip {
  white-space: pre-line;
  text-align: left;
}
